import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { Tab } from 'components/v2/Tabs/Tab.types';
import { Tabs } from 'components/v2/Tabs/Tabs';
import { getTabRequestedInQueryString } from 'components/v2/Tabs/Tabs.utils';
import { useHomeMarket } from 'hooks';
import { useQueryParams } from 'hooks/useQueryParams';
import { themes } from 'kb-shared';
import { ResponsiveContainer } from 'screens/styled_common';
import { AbsoluteCentered } from 'styled';
import { analytics } from 'utilities/analytics';
import { pageUrl } from 'utilities/pageUrl';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { CyclePayments } from './components/CyclePayments/CyclePayments';
import { InvoicePayModal } from './components/InvoicePayModal/InvoicePayModal';
import { InvoicesPageHeader } from './components/InvoicesPageHeader/InvoicesPageHeader';
import { PageableInvoices } from './components/PagableInvoices/PagableInvoices';
import { PayCycleModal } from './components/PayCycleModal/PayCycleModal';
import { UnappliedPayments } from './components/UnappliedPayments/UnappliedPayments';
import useInvoices from './hooks/use-invoices';
import useTotalBalance from './hooks/use-total-balance';
import useUnappliedPayments from './hooks/use-unapplied-payments';
import Invoice from './types/invoice';

export function Invoices() {
  const [invoiceToPay, setInvoiceToPay] = useState<Invoice | undefined>(undefined);
  const [paymentWithoutInvoiceModalOpen, setPaymentWithoutInvoiceModalOpen] = useState(false);
  const { vios: isViosPatient } = useHomeMarket();
  const history = useHistory();
  const queryString = useQueryParams();
  const { isMobile } = useBreakpoints();
  const { totalBalance, loading: totalBalanceLoading } = useTotalBalance();
  const [selectedTab, setSelectedTab] = useState<Tab | undefined>(
    getTabRequestedInQueryString(queryString, tabs)
  );
  const PAGE_SIZE = 10;

  const {
    loading: invoicesLoading,
    error: invoicesError,
    invoices,
    currentPage,
    onNextPage,
    onPreviousPage,
    refetch: refetchInvoices
  } = useInvoices(PAGE_SIZE, totalBalanceLoading);

  const {
    unappliedPayments,
    error: unappliedPaymentsError,
    loading: unappliedPaymentsLoading,
    refetch: refetchUnappliedPayments
  } = useUnappliedPayments(totalBalanceLoading);

  const onViewInvoice = (invoice: Invoice) => {
    const invoicesPage = pageUrl.invoices({
      invoiceIdentifier: getInvoiceIdentifier(invoice)
    });
    history.push(invoicesPage);
  };

  const onPayInvoiceWithCredit = (invoice: Invoice) => {
    const invoiceIdentifier = getInvoiceIdentifier(invoice);
    const payWithCreditUrl = pageUrl.messages({
      categoryName: 'billing',
      subject: `Invoice ${invoiceIdentifier} - Pay with Credit`,
      message: 'Please reconcile account and apply available credits to any open balances.'
    });
    history.push(payWithCreditUrl);
  };

  useEffect(() => {
    analytics.page(analytics.PAGES.INVOICES);
  }, []);

  const onChangeTab = (tab?: Tab) => {
    setSelectedTab(tab);
    if (tab?.id) return history.push(`?tab=${tab.id}`);
    history.push();
  };

  if (totalBalanceLoading || invoicesLoading || unappliedPaymentsLoading) {
    return (
      <AbsoluteCentered>
        <Loader type="spin" color={themes.colors.yellow.primary} height={150} width={150} />
      </AbsoluteCentered>
    );
  }

  const refetchData = () => {
    refetchUnappliedPayments();
    refetchInvoices();
  };

  const closeInvoicePayModal = () => setInvoiceToPay(undefined);
  const onInvoicePaid = () => {
    closeInvoicePayModal();
    refetchData();
  };

  const closeCylceModal = () => setPaymentWithoutInvoiceModalOpen(false);
  const onCyclePaid = () => {
    closeCylceModal();
    refetchData();
  };

  const isTabSelectedOnSmallScreen = isMobile && !!selectedTab;

  return (
    <ResponsiveContainer paddedContent>
      {!isTabSelectedOnSmallScreen && <InvoicesPageHeader totalBalance={totalBalance} />}

      <Tabs tabs={tabs} initialTab={selectedTab} onChangeTab={onChangeTab} noMargin>
        {selectedTab?.id === 'unapplied-payments' && (
          <UnappliedPayments unappliedPayments={unappliedPayments} error={unappliedPaymentsError} />
        )}
        {selectedTab?.id === 'payments' && (
          <CyclePayments onMakeCyclePayment={() => setPaymentWithoutInvoiceModalOpen(true)} />
        )}
        {selectedTab?.id === 'invoices' && (
          <PageableInvoices
            invoices={invoices}
            totalBalance={totalBalance}
            currentPage={currentPage}
            onInvoiceSelected={invoice => setInvoiceToPay(invoice)}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            isViosPatient={Boolean(isViosPatient)}
            onViewInvoice={onViewInvoice}
            onPayWithCredit={onPayInvoiceWithCredit}
            error={invoicesError}
          />
        )}
      </Tabs>

      {invoiceToPay && (
        <InvoicePayModal
          invoice={invoiceToPay}
          open={true}
          onClose={closeInvoicePayModal}
          onPaid={onInvoicePaid}
        />
      )}
      {paymentWithoutInvoiceModalOpen && (
        <PayCycleModal
          open={paymentWithoutInvoiceModalOpen}
          onClose={closeCylceModal}
          onPaid={onCyclePaid}
        />
      )}
    </ResponsiveContainer>
  );
}

const getInvoiceIdentifier = (invoice: Invoice) => invoice.azEncounterIdentifier || invoice.id;

const tabs: Tab[] = [
  {
    id: 'invoices',
    label: 'Invoices'
  },
  {
    id: 'payments',
    label: 'Payments'
  },
  {
    id: 'unapplied-payments',
    label: 'Unapplied Payments'
  }
];
