import { ApolloError } from '@apollo/client';
import Honeybadger from '@honeybadger-io/js';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as IconMail } from 'assets/icons/MyProfile/mail.svg';
import { ReactComponent as IconTelephone } from 'assets/icons/MyProfile/telephone.svg';
import {
  CountrySelectField,
  PhoneFieldBasic,
  PreferredTimezoneSelectField,
  SelectField,
  TextField,
  ZipField
} from 'components/v2/Form';
import { LegalAgreements } from 'components/v2/LegalAgreements/LegalAgreements';
import { Heading, Text as TextNew } from 'components/v2/Typography';
import { useFeatureFlags } from 'hooks';
import { appLoaded } from 'kb-redux/user.redux';
import { AuthProvider, DropdownOption, utils } from 'kb-shared';
import theme from 'kb-shared/theme';
import { getCognitoAccessToken } from 'kb-shared/utilities/api';
import { RoutesPath } from 'utilities/pageUrl';
import { scrubPhoneNumber } from 'utilities/phoneNumber';
import { isUS } from 'utilities/userUtil';

import { showErrorToast } from '../../../../utilities/notificationUtils';
import { validateAddressFields } from '../helpers';
import { Container, Icon, TwoColumnGrid } from '../shared.styled';
import { useContactInfoFieldsMutations } from './ContactInfoFields.graphql';
import { ClickableText } from './ContactInfoFields.styled';
import { EmailDisclaimerModal } from './EmailDisclaimerModal';
import { DisableMfaModal } from './MfaModal/DisableMfaModal';
import { EnableMfaModal } from './MfaModal/EnableMfaModal';

const { states, COUNTRIES_OPTIONS } = utils;

type Props = {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  email?: string;
  phone?: string;
  country?: string;
  preferredTimezone?: string;
  authProviders: AuthProvider[];
  mfaEnabled?: boolean | undefined;
  sendSmsAppointmentNotification?: boolean;
  sendMarketingEmail?: boolean;
};

type Address = {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country: string;
};

export default function ContactInfoFields({
  address1,
  address2,
  city,
  state,
  zipcode,
  email,
  phone,
  country = '',
  preferredTimezone,
  authProviders,
  mfaEnabled,
  sendSmsAppointmentNotification,
  sendMarketingEmail
}: Props) {
  const isEmptyCountry = country === undefined;
  const dispatch = useDispatch();
  const initialStateOption = states.find(item => item.value === state);
  const initialCountryOption = COUNTRIES_OPTIONS.find(item => item.value === country);
  const { updatePatient, updateAddress } = useContactInfoFieldsMutations();
  const [address, setAddress] = useState<Address>({
    address1,
    address2,
    city,
    state,
    zipcode,
    country
  });
  const [phoneDetails, updatePhoneDetails] = useState(phone);
  const [countrySelectedOption, updateCountrySelectedOption] = useState(initialCountryOption);
  const { patientEmailChange, mfaSignIn } = useFeatureFlags();
  const [openDisclaimerModal, setOpenDisclaimerModal] = useState(false);
  const [openEnableMfaModal, setOpenEnableMfaModal] = useState(false);
  const [openDisableMfaModal, setOpenDisableMfaModal] = useState(false);
  const isUsMode = isEmptyCountry || isUS(address.country);
  const optionalFields = ['address2', 'state', 'zipcode'];
  const [smsNotification, setSmsNotification] = useState(sendSmsAppointmentNotification || false);
  const [marketingEmail, setMarketingEmail] = useState(sendMarketingEmail);
  const [timeZone, setTimeZone] = useState(preferredTimezone);

  const handleAddressChange = (fieldName: string, fieldValue: string) => {
    setAddress({
      ...address,
      [fieldName]: fieldValue
    });
  };

  const handleStateChange = (option: DropdownOption) => {
    setAddress({ ...address, state: option.value });
    const errors = validateAddressFields(address, optionalFields);
    if (errors.length > 0) {
      showErrorToast(`${errors.join(', ')} can't be blank`);
    } else {
      updateAddress({
        variables: {
          ...address,
          state: option.value
        }
      }).catch((res: ApolloError) =>
        handleError(res, "STATE can't be saved at the moment. Please try again.")
      );
    }
  };

  const handleAddressBlur = (fieldName: string) => {
    const targetId = fieldName.toUpperCase();
    const errors = isUS(address.country) ? validateAddressFields(address, optionalFields) : [];

    if (errors.length > 0) {
      const connector = errors.length > 2 ? ',' : '';
      const errorMessage =
        errors.length > 1
          ? `${errors.slice(0, -1).join(', ')}${connector} and ${
              errors[errors.length - 1]
            } can't be blank`
          : `${errors.join(', ')} can't be blank`;

      showErrorToast(capitalize(errorMessage));
    } else {
      updateAddress({
        variables: {
          ...address
        }
      }).catch((res: ApolloError) =>
        handleError(res, `${targetId} can't be saved at the moment. Please try again.`)
      );
    }
  };

  const handleCountryChange = (option: DropdownOption) => {
    const updatedAdress = {
      ...address,
      country: option.value,
      state: isUS(option.value) ? initialStateOption?.value : '',
      zipcode: ''
    };

    updateAddress({ variables: updatedAdress })
      .then(() => {
        setAddress(updatedAdress);
        updateCountrySelectedOption(option);
      })
      .catch((res: ApolloError) =>
        handleError(res, "COUNTRY can't be saved at the moment. Please try again.")
      );
  };

  const handlePreferredTimezone = (option: DropdownOption) => {
    try {
      setTimeZone(option.label);
      updatePatient({
        variables: {
          preferredTimezone: option.label
        }
      })
        .then(() => dispatch(appLoaded()))
        .catch((res: ApolloError) =>
          handleError(res, "PREFERRED TIMEZONE can't be saved at the moment. Please try again.")
        );
    } catch (e) {
      handleError(
        e as ApolloError,
        "PREFERRED TIMEZONE can't be saved at the moment. Please try again."
      );
    }
  };

  const handlePhoneDetails = (newPhone?: string) => {
    updatePatient({
      variables: {
        phone: newPhone || phoneDetails
      }
    }).catch((res: ApolloError) => {
      handleError(res, "MOBILE OR PHONE NUMBER can't be saved at the moment. Please try again.");
    });
  };

  const handleError = (err: ApolloError, messageForUser: string) => {
    Honeybadger.notify(err);
    showErrorToast(messageForUser);
  };

  const handleUpdateEmailClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (authProviders.includes(AuthProvider.EmailPassword) && !getCognitoAccessToken()) {
      event.preventDefault();
      setOpenDisclaimerModal(true);
    }
  };

  const handleSmsCheckbox = () => {
    updatePatient({
      variables: {
        sendSmsAppointmentNotification: !smsNotification
      }
    })
      .then(() => setSmsNotification(!smsNotification))
      .catch((res: ApolloError) =>
        handleError(res, "SMS preference can't be saved at the moment. Please try again.")
      );
  };

  const handleMarketingEmail = () => {
    updatePatient({
      variables: {
        sendMarketingEmail: !marketingEmail
      }
    })
      .then(() => setMarketingEmail(!marketingEmail))
      .catch((res: ApolloError) =>
        handleError(res, "Email preference can't be saved at the moment. Please try again.")
      );
  };

  return (
    <>
      <Container>
        <TwoColumnGrid>
          <div>
            <Heading tag="h2" styledAs="h4">
              <Icon>
                <IconMail />
              </Icon>
              Email Address
            </Heading>

            <TextField
              id="email-input"
              label="Email Address"
              placeholder="Email Address"
              value={email}
              status="readOnly"
              helperText={
                patientEmailChange ? (
                  <TextNew size="sm">
                    <Link onClick={handleUpdateEmailClick} to={RoutesPath.CHANGE_EMAIL}>
                      <TextNew fontStyle="bold" color={theme.colors.blue.darkBlue}>
                        Click here{' '}
                      </TextNew>
                    </Link>
                    to update your email address
                  </TextNew>
                ) : (
                  <Link to="/messages">
                    <TextNew size="sm" transform="uppercase" color={theme.colors.blue.darkBlue}>
                      Click here to send us a message if you would like to update your email address
                    </TextNew>
                  </Link>
                )
              }
            />
          </div>

          <div>
            <Heading tag="h2" styledAs="h4">
              <Icon>
                <IconTelephone />
              </Icon>
              Telephone number
            </Heading>

            <PhoneFieldBasic
              id="phone-input"
              country={isUsMode ? 'US' : 'other'}
              label="Mobile or Phone Number"
              placeholder="(+XXXX)Phone Number"
              value={phoneDetails}
              onChange={e => updatePhoneDetails(scrubPhoneNumber(e.currentTarget.value))}
              onBlur={() => handlePhoneDetails()}
              helperText={
                mfaSignIn ? (
                  <TextNew size="sm">
                    <ClickableText
                      onClick={() =>
                        mfaEnabled ? setOpenDisableMfaModal(true) : setOpenEnableMfaModal(true)
                      }
                    >
                      <TextNew fontStyle="bold" color={theme.colors.blue.darkBlue}>
                        Click here{' '}
                      </TextNew>
                    </ClickableText>
                    to {mfaEnabled ? 'disable' : 'enable'} SMS 2FA authentication.
                  </TextNew>
                ) : (
                  <></>
                )
              }
            />
          </div>

          <LegalAgreements
            sendSmsNotificationSelected={smsNotification}
            sendMarketingEmailSelected={!!marketingEmail}
            onSmsNotificationChange={handleSmsCheckbox}
            onMarketingEmailChange={handleMarketingEmail}
          />
        </TwoColumnGrid>

        <TwoColumnGrid>
          <div>
            <Heading tag="h2" styledAs="h4">
              <Icon>
                <IconMail />
              </Icon>
              Mailing address
            </Heading>
            <CountrySelectField
              id="country-select"
              label="Country"
              placeholder="Select country"
              searchPlaceholder="Search for a country"
              selected={countrySelectedOption}
              options={COUNTRIES_OPTIONS}
              onChange={handleCountryChange}
            />
          </div>
        </TwoColumnGrid>

        <TwoColumnGrid>
          <TextField
            id="address-1-input"
            label="Address line 1"
            placeholder="Enter address line 1"
            value={address.address1}
            onChange={e => handleAddressChange('address1', e.currentTarget.value)}
            onBlur={() => handleAddressBlur('address line 1')}
          />

          <TextField
            id="address-2-input"
            label="Address line 2"
            placeholder="Enter address line 2"
            value={address.address2}
            onChange={e => handleAddressChange('address2', e.currentTarget.value)}
            onBlur={() => handleAddressBlur('address line 2')}
          />

          <TextField
            id="city-input"
            label="City"
            placeholder="Enter city"
            value={address.city}
            onChange={e => handleAddressChange('city', e.currentTarget.value)}
            onBlur={() => handleAddressBlur('city')}
          />

          <TwoColumnGrid>
            {isUsMode ? (
              <>
                <SelectField
                  id="state-select"
                  label="State"
                  placeholder="Select state"
                  selected={initialStateOption}
                  options={states}
                  onChange={handleStateChange}
                />
              </>
            ) : (
              <TextField
                id="state-input"
                label="State"
                placeholder="Enter state"
                value={address.state}
                onChange={e => handleAddressChange('state', e.currentTarget.value)}
                onBlur={() => handleAddressBlur('state')}
              />
            )}

            <ZipField
              id="zipcode-input"
              placeholder="Enter ZIP code"
              zipType={isUsMode ? 'US' : 'other'}
              label="Zip code"
              value={address.zipcode}
              onChange={e => handleAddressChange('zipcode', e.currentTarget.value)}
              onBlur={() => handleAddressBlur('zip code')}
            />
          </TwoColumnGrid>

          <PreferredTimezoneSelectField
            id="preferred-timezone-select"
            onChange={handlePreferredTimezone}
            selected={timeZone}
            label="Preferred Timezone"
            placeholder="Select preferred timezone"
            searchable
            searchPlaceholder="Search for a timezone"
          />
        </TwoColumnGrid>
      </Container>

      <EmailDisclaimerModal
        open={openDisclaimerModal}
        onClose={() => setOpenDisclaimerModal(false)}
      />
      {phone && (
        <EnableMfaModal
          open={openEnableMfaModal}
          onClose={mfaPhone => {
            setOpenEnableMfaModal(false);
            if (mfaPhone) {
              updatePhoneDetails(mfaPhone);
              handlePhoneDetails(mfaPhone);
            }
          }}
          phone={phone}
          isUsPatient={isUsMode}
        />
      )}
      {phone && (
        <DisableMfaModal
          open={openDisableMfaModal}
          onClose={() => {
            setOpenDisableMfaModal(false);
            handlePhoneDetails(phone);
          }}
        />
      )}
    </>
  );
}
